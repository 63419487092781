import * as Cookies from 'tiny-cookie'

import {
	indexOf,
	sum
} from 'lodash'

import axios from 'axios'

export function preloadResource(urls){
	let calls = []

	urls.forEach(url => {
		calls.push(axios.get(url))
	})

	return Promise.all(calls)
}

export function preloadImages(images, callback) {
	var imagesToLoad = 0;

	// Nothing to do
	if(!images.length){
		if(callback){
			callback();
		}

		return;
	}

	for(var i = 0; i < images.length; i++) {
		var image = new Image();
		image.src = images[i];
		imagesToLoad++;

		var on = () => {
			imagesToLoad--;
			if(imagesToLoad == 0 && callback){ // Seems everything is loaded
				callback()
				return;
			}
		}

		image.onload = on
		image.onerror = on
	}
}

export function findBootstrapEnvironment(){
	var envs = ['xs', 'sm', 'md', 'lg']
	var $el = document.createElement("div")

	document.body.insertBefore($el, document.body.firstChild)

	for (var i = envs.length - 1; i >= 0; i--) {
		var env = envs[i];

		$el.className += ' hidden-' + env
		if(isHidden($el)){
			// Removing a specified element without having to specify its parent node
			if ($el.parentNode) {
				$el.parentNode.removeChild($el);
			}
			
			return env;
		}
	}
}

export function isHidden (el){
	var style = window.getComputedStyle(el);
	return (style.display === 'none')
}

// Taken from https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
export function youtubeParser(url){
	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
	var match = url.match(regExp);
	return (match && match[7].length==11)? match[7] : false;
}

export function makeYoutubeIframe(url) {
	return '<iframe width="420" height="315" src="https://www.youtube.com/embed/'+youtubeParser(url)+'"></iframe>'
}

export function makeYoutubeIframeResponsive(string){
	var youtubeHtml = '<div style="position: relative;  width: 100%; height: 0; padding-bottom: 56.25%;"><iframe src="$1?hl=hr&iv_load_policy=3&modestbranding=1&rel=0&showinfo=0" frameborder="0" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>';
	return string.replace(/<iframe.*?src="(.*?youtube\.com.*?)".*?<\/iframe>/mg, youtubeHtml);
}

export function getTranslation(translations, language) {
	// No translations at all
	if(!translations || typeof translations !== 'object') return ""

	// If language var is not provided take the global one
	if(!language && typeof currentLanguage !== "undefined") language = currentLanguage

	// Check if we have all necessary data. If not fallback to any first language
	if(typeof language === "undefined" || !language || !translations[language]){
		for (var i = 0; i < Object.keys(translations).length; i++) {
			if(translations[Object.keys(translations)[i]]) return translations[Object.keys(translations)[i]]
		}

		return ""
	}

	// Return correct translation
	return translations[language]
}

export function getChallengeImageUrl(imageId){
	if(parseInt(imageId)){
		return '/image/i.'+ imageId + '/w.400:h.400:f.0/image.png'
	}else{
		return '/challenge-images/' + (imageId ? imageId : '_.png')
	}
}

export function getChallengeTypeConfig(type){
	if(!type){
		return;
	}
	
	return axios.get("/api/admin-area/getChallengeTypeConfig/" + type)
}

export function replaceArray( str, findArray, replaceArray ){
	var i, regex = [], map = {}; 
	for( i=0; i<findArray.length; i++ ){ 
		regex.push( findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g,'\\$1') );
		map[findArray[i]] = replaceArray[i]; 
	}

	regex = regex.join('|')
	
	str = str.replace( new RegExp(regex, 'g'), (matched) => {
		return map[matched]
	})

	return str
}

export function getSuppotedBrowserLanguages(){
	return navigator.languages.map(lang => lang.split("-")[0])
}

// Return language if detected or false if not found
export function isLanguageInPath(supportedLanguages, path){
	let firstUrlToken = path.split('/')[1]
	let language = false

	supportedLanguages.forEach(item => {
		if(firstUrlToken == item.key){
			language = item.key
		}
	})
	
	return language
}

export function detectLanguage(languages, defaultLanguage){
	// Try to figure out if we have language in the URL
	let language = isLanguageInPath(languages, window.location.pathname)
	
	if(language) return language;
	
	// If nothing in URL lets look to cookie:
	language = Cookies.get('language')
	
	if(language) return language;
	
	let browserLangs = getSuppotedBrowserLanguages()
	
	languages.some(item => {
		if(indexOf(browserLangs, item.key) >= 0){
			language = item.key
			return true;
		}
	})
	
	return language ? language : defaultLanguage;
}

export function setLanguageCookie(language){
	Cookies.set('language', language, {
		expires: '3Y',
		SameSite: 'strict',
		secure: true
	})
}

export const loadScript = async(url) => {
	let contents = await axios.get(url)
	
	eval(contents.data);
}

export function validateEmail(email) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

export function containsOnlyNumbers(str) {
  return /^\d+$/.test(str);
}

export function containsAnyString(element){
	return sum(Object.keys(element).map(i => element[i].trim().length)) == 0
}

export function defaultConfirm(title, params = {}){
	return new Promise(resolve => {
		var html = ''
		
		if(params.image) html += '<img  class="modal-image" src="' + params.image + '" alt="">'
		else if(params.imageInline) html += params.imageInline

		html += '<div class="modal-title">' + title + '</div>'
		if(params.text) html += '<div class="modal-text">' + params.text + '</div>'

		$app.openGenericPopup(html,
			{
				buttonText: params.buttonText ? params.buttonText : $app.t.genericYes,
				buttonCancelText: $app.t.genericCancel,
				showCancel: true,
				buttonClass: params.buttonClass ? params.buttonClass : 'btn-primary',
				callback: resolve,
			}
		)
	})
}

export function defaultErrorModal(title, params = {}){
	return new Promise(resolve => {
		var html = ""

		if(params.image) html += '<img  class="modal-image" src="' + params.image + '" alt="">'
		else if(params.imageInline) html += params.imageInline
		
		html += '<div class="modal-title">' + title + '</div>'
		if(params.text) html += '<div class="modal-text">' + params.text + '</div>'

		$app.openGenericPopup(html,
			{
				buttonText: params.buttonText ? params.buttonText : $app.t.genericClose,
				buttonClass: params.buttonClass ? params.buttonClass : 'btn-default',
				callback: resolve,
			}
		)
	})
}

export function insertText(token){
	if(!token) return '';
	
	for (var i = 0; i < arguments.length; i++) { token = token.replace("%" + i, arguments[i]) }
	
	return token
}

// Strip HTML tags from string
export function stripTags(str){
	return str.replace(/(<([^>]+)>)/ig, "")
}

export function formatPrizeCost(cost){	return this.t && insertText(this.t.rewardsCostFormat, cost)	}

export function humanize(value){
	return moment(value).from()
		.replace(/(za|prije) dan/, '$1 1 dan') /* Fix for croatian language */
		.replace(/(za|prije) mjesec/, '$1 mjesec dana')
}

// It is a helper for modals to change URL so "Back" could work
export function setHashValue(url, value){
	value ?
		history.pushState({}, null, url + '#' + encodeURIComponent(value)) : 
		history.replaceState({}, null, url) 
}

// Get value in seconds
export function getTimerText(val){
	if(isNaN(val)) return 'NaN'

	if(parseInt(val / 86400) > 0){
		return moment().add(val, 's').fromNow(true) // Parse string if more than 1 day in future
	}

	var days = parseInt(val/86400)
	var hours = parseInt(val%86400/3600)
	var minutes = parseInt((val%(3600))/60)
	var seconds = val%60

	if(days){
		return sprintf('%dd %02d:%02d:%02d', days, hours, minutes, seconds)
	}else if(hours){
		return sprintf('%d:%02d:%02d', hours, minutes, seconds)
	}else if(minutes){
		return sprintf('%d:%02d', minutes, seconds)
	}else if(seconds){
		return sprintf('%ds', seconds)
	}
}

export function randomHash (nChar) {
	// convert number of characters to number of bytes
	var nBytes = Math.ceil(nChar = (+nChar || 32) / 2)

	// create a typed array of that many bytes
	var u = new Uint8Array(nBytes)

	// populate it wit crypto-random values
	window.crypto.getRandomValues(u)

	// convert it to an Array of Strings (e.g. "01", "AF", ..)
	var zpad = (str) => {
		return '00'.slice(str.length) + str
	}

	var a = Array.prototype.map.call(u, (x) => {
		return zpad(x.toString(16))
	})

	// Array of String to String
	var str = a.join('')

	// and snip off the excess digit if we want an odd number
	if (nChar % 2) str = str.slice(1)

	// return what we made
	return str
}

// Generate random key safe to share
export function generateKey(length = 32){
	// These are safe symbols for reading with 1/l and 0/O removed
	let safeSymbols = 'abcdefghijkmnpqrstuvwxyzABCDEFGHIJKMNPQRSTUVWXYZ23456789';
	let safeSymbolsLength = safeSymbols.length
	let string = ""

	for(let i = 0; i < length; i++) string += safeSymbols[Math.floor(Math.random() * safeSymbolsLength)]

	return string
}

// This helper is for vue-infinite-loader component. Sometimes we need to trigger it manually, for example on a first load
// https://github.com/PeachScript/vue-infinite-loading/issues/303
export function triggerInfiniteLoader(ref){
	ref.$emit("$InfiniteLoading:reset", { target: ref, });
	ref.status = 1
	ref.$emit('infinite', ref.stateChanger)
}

// Replace "\n" to a newline in textarea placeholders on the page
export function fixTextareaPlaceholderNewlines(){
	// Fixing textarea placeholder
	var textAreas = document.getElementsByTagName('textarea')

	Array.prototype.forEach.call(textAreas, elem => {
		elem.placeholder = elem.placeholder.replace(/\\n/g, '\n')
	})
}

// Check if the variable is a real object (not an array, null or regex)
export function isRealObject(obj){
	return (obj ?? false)?.constructor?.name === "Object"
}

// This is a helper to wait for the condition to happen, it should be wrapped in an async function:
// async function a(){
// 		await until(() => value == 5);	
// 		...
//		doSomethingWhenValueBecomes5()
// }
export function until(conditionFunction) {

  const poll = resolve => {
    if(conditionFunction()) resolve();
    else setTimeout(_ => poll(resolve), 400);
  }

  return new Promise(poll);
}