export function datepickerDisabledInPast(date) {
	const today = new Date()
	today.setHours(0, 0, 0, 0)

	return date < today
}

export function datepickerDisabledInFuture(date) {
	const today = new Date()
	today.setHours(0, 0, 0, 0)

	return date > today
}

export let datepickerShortcuts = [
	{ text: 'Today', onClick: () => [new Date(), new Date()] },
	{
		text: 'Yesterday',
		onClick: () => {
			const date = new Date();
			date.setTime(date.getTime() - 3600 * 1000 * 24);
			return [date, date];
		},
	},
	{
		text: 'Last 7 days',
		onClick: () => {
			const date = new Date()
			date.setDate(date.getDate() - 7)
			return [date, new Date()];
		},
	},
	{
		text: 'Last 30 days',
		onClick: () => {
			const date = new Date()
			date.setDate(date.getDate() - 30)
			return [date, new Date()];
		},
	},
	{
		text: 'This month',
		onClick: () => {
			const date = new Date()
			date.setDate(1)
			return [date, new Date()]
		},
	},
	{
		text: 'Last month',
		onClick: () => {
			const date = new Date()
			date.setDate(1)
			date.setMonth(date.getMonth()-1)
			return [date, new Date(date.getFullYear(), date.getMonth()+1, 0)]
		},
	},
	{
		text: 'This year',
		onClick: () => {
			const date = new Date()
			date.setDate(1)
			date.setMonth(0)
			return [date, new Date()]
		},
	},
	{
		text: 'Last year',
		onClick: () => {
			const date = new Date()
			date.setDate(1)
			date.setMonth(0)
			date.setFullYear(date.getFullYear()-1)
			return [date, new Date(date.getFullYear()+1, 0, 0)]
		},
	},
]
